import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import ResearchDetailsdescription from './ResearchDetailsdescription';

export default function ResearchDetails() {
  const [research, setResearch] = useState(null);

  const { id } = useParams();
  // console.log(id);

  useEffect(() => {
    // getData();

    if (research === null) {
      fetch("../research.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          // console.log(response);
          return response.json();
        })
        .then(function (myJson) {
          // console.log(myJson[id - 1]);
          setResearch(myJson[id - 1]);
        });
    }
  }, [research , setResearch]);
  return (
    <div className='max-w-7xl 2xl:max-w-hs -top-10 md:px-32 mx-auto bg-gray-900'>
      {
       research && <ResearchDetailsdescription researchDetails = {research} id={id}/>
      }
    </div>
  )
}
