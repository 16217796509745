import React from "react";
import { useNavigate } from "react-router-dom";
// import R1 from "../../../assets/project-images/deepfake.gif";
export default function ResearchList({ researchList }) {
  const navigate = useNavigate();
  return (
    <div className="md:first-line">
      <div className="md:grid md:grid-cols-12 ">
        {researchList.map((research) => (
          <div
            className="col-span-4 mx-2 bg-opacity-50 newCSS md:mb-10"
            data-aos="fade-up"
            key={research.id}
            onClick={() => {
              navigate(`/services/${research.id}`);
            }}
          >
            <img src={research.imgUrl} alt="research" className="max-h-[156px] mx-auto "/>
            <h1 className="text-xl md:text-2xl font-gfs font-extrabold  tracking-widest pb-2 pt-2 textColor hover:underline">
              {research.title}
            </h1>
            <p className="text-sm md:text-md 2xl:text-lg text-white font-serif text-left">
              {research.shortDes}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
