import React from "react";
import { Link } from "react-router-dom";
import "../services/ServicesCatagory";
import Img1 from "../../../assets/project-images/1.png";
import Img19 from "../../../assets/project-images/19.png";

export default function Services() {
  // const navigate = useNavigate();

  return (
    <div className="md:px-5 lg:px-20 2xl:mx-32 mx-auto">
      <div className="relative items-center justify-center  ">
        <div className="inset-0 md:min-h-screen -my-24 bg-cover bg-center opacity-75 ">
        </div>
        <div className="md:absolute inset-0 flex items-center justify-center md:min-h-screen w-full md:bg-gray-900 bg-opacity-75"></div>
        <div className="md:absolute inset-0   flex flex-col items-center justify-center">
          <div>
            <div className="md:grid md:grid-cols-12 my-auto boxShadow1">
              <div
                className="col-span-4 order-1 md:order-2 p-4 mx-auto "
                data-aos="fade-left"
              >
                <div className="">
                  <h1 className=" pt-4 text-3xl md:text-4xl font-bold font-orbitron text-white">
                    {/* Explore Best AI{" "}
                    <span className="textColor font-bold">Services</span> */}
                    Experience the Future with Our AI Powered{" "}
                    <span className="textColor font-bold"> Products</span>
                  </h1>
                  <p className="py-5 text-white font-serif">
                    Elevate Your Business with AI Powered Products by staying
                    ahead of the curve. Discover a whole new world of
                    possibilities with our cutting-edge AI technology
                  </p>
                </div>
              </div>
              <div className="md:col-span-8 p-2 order-3 md:order-1 md:py-4">
                <div className="lg:grid lg:grid-cols-8">
                  <div
                    className="col-span-4 p-4 md:px-6 m-4 md:m-2 lg:my-2 bg-gray-600 rounded-2xl hover:shadow-2xl box bg-opacity-50 newCSSServices"
                    data-aos="fade-up"
                  >
                    <div className=" hover:border-b border-blue-500 text-white">
                      <div className="iconbox">
                        <span>
                          <div
                            className="w-14 h-14 overflow-hidden rounded-full -rotate-45 my-auto mx-auto"
                            style={{ flexShrink: 0 }}
                          >
                            <img src={Img1} alt="jjf" />
                            {/* {Img11} */}
                          </div>
                        </span>
                      </div>
                      <h1 className="text-xl md:text-2xl mx-auto font-gfs font-extrabold tracking-widest py-2 hover:text-white textColor">
                        Virtual Try On - Clothes
                      </h1>
                    </div>
                    <p className="text-white text-sm md:text-base pt-2 text-justify font-poppins">
                      Transform Your Clothes Shopping Experience. Try on outfits
                      remotely and confidently find the perfect fit with our
                      AI-powered virtual try on technology.
                    </p>
                  </div>
                  <div
                    className="col-span-4 p-4 md:px-6 m-4 md:m-2 lg:m-2 bg-gray-600 rounded-2xl hover:shadow-2xl box bg-opacity-50 newCSSServices"
                    data-aos="fade-up"
                  >
                    <div className=" hover:border-b border-blue-500 text-white">
                      <div className="iconbox">
                        <span>
                          {/* <GiArtificialIntelligence
                            size={40}
                            className="-rotate-45 my-auto mx-auto"
                          /> */}
                          <div
                            className="w-14 h-14 overflow-hidden rounded-full -rotate-45 my-auto mx-auto"
                            style={{ flexShrink: 0 }}
                          >
                            <img src={Img19} alt="jjf" />
                            {/* {Img11} */}
                          </div>
                        </span>
                      </div>
                      <h1 className="text-xl md:text-2xl mx-auto font-gfs font-extrabold tracking-widest  py-2 hover:text-white textColor">
                        Virtual Try On - Makeup
                      </h1>
                    </div>
                    <p className="text-white text-sm md:text-base pt-2 text-justify font-poppins">
                      Revolutionize Your Makeup Routine. Try on makeup looks
                      from anywhere with our AI-powered virtual try on
                      technology, and find your perfect match with ease
                    </p>
                  </div>
                </div>
                <div className="col-span-12 text-center md:text-end md:pr-20 pt-16">
                  <Link to={"/services"}>
                    <button
                      className="btn2 text-black md:text-white bg-[#ff3d00] px-10 py-5 relative  md:border-white uppercase font-semibold tracking-wider leading-none overflow-hidden hover:text-white"
                      type="button"
                    >
                      <span className="absolute inset-0 bg-teal-600"></span>
                      <span className="absolute inset-0 flex justify-center items-center font-bold">
                        View All
                      </span>
                      View All
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
