import React, {useState, useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Img1 from "../../../assets/project-images/1.png";
import Img2 from "../../../assets/project-images/2.png";
import Img3 from "../../../assets/project-images/3.png";
import Img4 from "../../../assets/project-images/4.png";
import Img5 from "../../../assets/project-images/5.png";
import Img6 from "../../../assets/project-images/6.png";

const offers = [
  {
    logo: <img src={Img1} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Virtual Try-On",
    details:
      "Developing computer vision-based systems for virtual try-on experiences, such as virtual fitting rooms for clothing or makeup, allowing customers to try on products without physically being present.",
  },
  {
    logo: <img src={Img2} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Fashion Recommendation",
    details:
      "Providing computer vision-based solutions for fashion recommendation, such as personalized styling advice and product recommendations based on body type and preferences.",
  },
  {
    logo: <img src={Img3} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Intelligent Video Analytics",
    details:
      " Providing advanced video analytics services to clients, such as real-time object tracking, facial recognition, and automated video surveillance.",
  },
  {
    logo: <img src={Img4} alt="afd" className=" bg-white w-full h-full " />,
    heading: "3D Vision",
    details:
      "Developing solutions that allow for 3D sensing, reconstruction, and modeling. This can be used for applications such as 3D scanning, virtual and augmented reality, and autonomous navigation.",
  },
  {
    logo: <img src={Img5} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Natural Language Processing (NLP)",
    details:
      "Building systems that can analyze and interpret human language, including speech recognition, sentiment analysis, and language translation.",
  },
  {
    logo: <img src={Img6} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Medical Image Analysis",
    details:
      "Developing solutions for medical image analysis, such as tumor detection, image segmentation, and diagnosis support.      ",
  },
];

export default function Offer() {
  AOS.init({
    offset: 200,
    duration: 3000,
  });
  const navigate = useNavigate();
  const [maxOffers, setMaxOffers] = useState(() => {
    return window.innerWidth < 600 ? 3 : offers.length;
  });

  const handleResize = () => {
    const newMaxOffers = window.innerWidth < 600 ? 3 : offers.length;
    setMaxOffers(newMaxOffers);
  };

  // Add a resize listener to update the state when the window size changes
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="md:grid md:grid-cols-12 ">
        <div className="col-span-4 py-4 mx-auto left ">
          <div className="sticky1 pad">
            <h3 className="text-2xl pb-2 font-bold border-b-2 font-oswald tracking-widest text-white">
              Services We{" "}
              <span className="text-[#2edaf1] font-bold">Offer</span>
            </h3>
            <h1 className="pl-6 pt-4 text-3xl md:text-4xl font-bold font-late textColorBlack">
              {/* <span className="text-[#ff3d00]">Artificial intelligence</span> will help everyone succeed. */}
              Leveraging the latest advances in{" "}
              <span className="text-[#ff3d00]">Artificial Intelligence</span>{" "}
              technology in our services
            </h1>
          </div>
        </div>
        <div className="md:col-span-8 p-2 md:py-4 right">
          <div className="lg:grid lg:grid-cols-8">
            {offers.slice(0, maxOffers).map((offer) => (
              <div
                className="col-span-4 p-4 md:px-8 m-4 md:m-2 bg-gray-600 rounded-2xl hover:shadow-2xl box bg-opacity-50 newCSS"
                data-aos="fade-up"
                key={offer.heading}
              >
                <div className="flex justify-between items-center text-right hover:pb-2 hover:border-b border-[#2edaf1] ">
                  <div
                    className="w-14 h-14 overflow-hidden rounded-full"
                    style={{ flexShrink: 0 }}
                  >
                    {offer.logo}
                  </div>
                  <h1 className="text-xl md:text-2xl ml-4  font-gfs font-extrabold tracking-widest py-2  textColor">
                    {offer.heading}
                  </h1>
                </div>
                <p className="text-white text-sm md:text-base pt-2 text-justify font-poppins hidden md:block">
                  {offer.details}
                </p>
              </div>
            ))}
          </div>
          <div className="col-span-12 text-center md:pt-16">
            <button
              className="btn2 text-black md:text-white bg-[#ff3d00] px-10 py-5 relative  md:border-white uppercase font-semibold tracking-wider leading-none overflow-hidden hover:text-white"
              type="button"
              onClick={() => {
                navigate(`/offer-list`);
              }}
            >
              <span className="absolute inset-0 bg-teal-600"></span>
              <span className="absolute inset-0 flex justify-center items-center font-bold">
                More Offers
              </span>
              More Offers
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
