import React, { useState, useEffect } from "react";
import ResearchList from "./ResearchList";
import ResearchHeading from "./ResearchHeading";
// import { Parallax , ParallaxProvider } from 'react-scroll-parallax';
export default function Reserach() {
  const [research, setResearch] = useState([]);
  const getData = () => {
    fetch("research.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response)
        return response.json();
      })
      .then(function (myJson) {
        // console.log(myJson);
        setResearch(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" bg-gray-900">
      <div className="max-w-7xl md:px-20 2xl:max-w-hs mx-auto">
        <section className="pb-6 md:pb-10 px-2">
          <ResearchHeading />
        </section>
        <section className="pb-10">
          <ResearchList researchList={research} />
        </section>
      </div>
    </div>
  );
}
