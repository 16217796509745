import React, { useEffect } from "react";
import Img11 from "../../../assets/project-images/11.png";
import Img12 from "../../../assets/project-images/12.png";
import Img13 from "../../../assets/project-images/7.png";
import Img14 from "../../../assets/project-images/14.png";
import Img15 from "../../../assets/project-images/15.png";
import Img16 from "../../../assets/project-images/16.png";
import Img17 from "../../../assets/project-images/17.png";
import Img18 from "../../../assets/project-images/18.png";
import ImgRe from "../../../assets/project-images/glaze.gif";

const researchs = [
  {
    logo: <img src={Img12} alt="afd" className=" bg-white w-full h-full " />,
    heading: " Deep Fake",
    details:
      "Deepfake research involves the development and analysis of techniques and technologies used to create, detect, and mitigate deep fake videos and images. It typically includes research on machine learning, computer vision, and other related fields, and seeks to improve our understanding of the risks and benefits associated with deepfake technology. The ultimate goal of deepfake research is to develop effective tools and strategies for identifying and preventing the spread of harmful deepfake content while also exploring potential positive applications of the technology.",
  },
  {
    logo: <img src={Img13} alt="afd" className=" bg-white w-full h-full " />,
    heading: " Remote Sensing",
    details:
      "Gathering and analyzing information about objects or phenomena from a distance using remote sensors, and using this data to gain insights and make predictions about various phenomena such as weather patterns, land use, and natural disasters. It has applications in fields such as agriculture, environmental monitoring, urban planning, and natural resource management.",
  },
  {
    logo: <img src={Img14} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Transfer Learning",
    details:
      "Branch of deep learning focusing on transferring knowledge learned from one domain to another. It involves using pre-trained models on large datasets to accelerate training on new datasets with limited labeled data. Transfer learning research aims to develop effective techniques for transferring knowledge between different domains and improving the performance of deep learning models in various applications.",
  },
  {
    logo: <img src={Img15} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Applications in Industry",
    details:
      "Providing insights into the real-world use cases and challenges faced by industries while implementing a technology, and the potential benefits and impact it can have on their operations. The research involves analyzing case studies, conducting surveys, and gathering data from industry experts and stakeholders to gain a deeper understanding of how a technology can be tailored to suit specific industry needs.",
  },
  {
    logo: <img src={Img16} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Market Analysis",
    details:
      "Collecting and evaluating data related to a specific market to gain insights into market trends, customer preferences, and competitor activities. The research involves analyzing various factors such as market size, growth rate, demographics, customer behavior, and industry trends to identify opportunities and potential risks. The findings of the market analysis research can help businesses make informed decisions about product development, marketing strategies, and market entry.",
  },
  {
    logo: <img src={Img17} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Supply Chain Analysis",
    details:
      "Studying the processes and activities involved in the production and distribution of goods and services, from raw material procurement to the delivery of the final product to the end customer. It involves analyzing the different stages of the supply chain, identifying bottlenecks and inefficiencies, and finding ways to optimize the flow of goods and services. The goal of supply chain research is to improve the efficiency, quality, and cost-effectiveness of the supply chain, leading to increased profitability and customer satisfaction.",
  },
  {
    logo: <img src={Img18} alt="afd" className=" bg-white w-full h-full " />,
    heading: " Consumer Sentiment Analysis",
    details:
      "Analyzing and interpreting consumer opinions, attitudes, and emotions about a particular product, service, or brand. The process involves using natural language processing and machine learning techniques to extract insights from unstructured data sources, such as social media posts, customer reviews, and survey responses. The goal of consumer sentiment analysis research is to understand how consumers perceive a brand or product, identify areas for improvement, and make data-driven decisions to improve customer satisfaction and loyalty.",
  },
];

export default function ResearchList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" bg-gray-900 md:pb-10">
      <div className="md:first-line 2xl:mx-36 max-w-7xl 2xl:max-w-hs md:px-20  mx-auto">
        <div className="relative items-center text-center justify-center flex md:pb-10">
          <div className="relative">
            <img
              src={ImgRe}
              alt="research"
              className=" opacity-50 object-cover w-screen mx-auto h-[512px]"
            />
          </div>
          <h1 className="absolute items-center justify-center flex  text-white font-extrabold text-xl md:text-5xl 2xl:text-7xl font-orbitron">
            Explore Our Best Research & Innovation
          </h1>
        </div>
        <div className="lg:grid lg:grid-cols-12 gap-4 m-4 md:m-0">
          <div className="col-span-8 p-2  md:p-8 pt-10  rounded-lg bg-[#ec6137] my-2 md:my-0">
            <h1 className="text-2xl md:text-3xl text-black font-gfs font-extrabold tracking-widest pb-4 pt-2">
              State of the Art <strong className="text-white">AI </strong>{" "}
              Research & Deployment
            </h1>
            <p className="text-sm md:text-base text-black font-poppins text-justify">
              AinoviQ is dedicated to pushing the boundaries of artificial
              intelligence and innovation to develop cutting-edge products and
              services. We invest heavily in research and development to stay at
              the forefront of the AI industry, leveraging state-of-the-art
              technologies and techniques to drive innovation and bring our
              clients the most advanced solutions. Our team of experts has deep
              expertise in computer vision, natural language processing, and
              machine learning, enabling us to create customized solutions that
              address even the most complex business challenges. Through our
              AI-powered products and services, we empower our clients to stay
              competitive in today's rapidly evolving digital landscape and
              unlock new opportunities for growth and success.
            </p>
          </div>
          <div
            className="col-span-4  p-4 md:p-8 2xl:p-8 bg-gray-600 rounded-2xl newCSS my-2 md:my-0"
            data-aos="fade-up"
          >
            <div
              className="w-14 h-14 overflow-hidden rounded-full"
              style={{ flexShrink: 0 }}
            >
              <img src={Img11} alt="jjf" />
              {/* {Img11} */}
            </div>
            <h1 className="text-xl md:text-2xl font-gfs font-extrabold  tracking-widest pb-2 pt-2 text-[#ff3d00]">
              Data Augmentation
            </h1>
            <p className="text-sm md:text-base text-white font-poppins text-justify">
              Exploring techniques to increase the diversity and size of a
              dataset used for training a machine learning model. This process
              involves creating new data samples by applying various
              transformations to the existing data samples, such as flipping,
              rotating, and cropping images. The goal of data augmentation
              research is to improve the generalization and robustness of
              machine learning models by providing them with more diverse and
              representative data samples.
            </p>
          </div>

          <>
            {researchs.map((research) => (
              <div
                className="col-span-4 p-4 md:px-8  bg-gray-600 rounded-2xl newCSS my-2 md:my-0"
                data-aos="fade-up"
                key={research.heading}
              >
                {/* {research.logo} */}
                <div
                  className="w-14 h-14 overflow-hidden rounded-full"
                  style={{ flexShrink: 0 }}
                >
                  {research.logo}
                </div>
                <h1 className="text-xl md:text-2xl font-gfs font-extrabold  tracking-widest pb-2 pt-2 text-[#ff3d00]">
                  {research.heading}
                </h1>
                <p className="text-sm md:text-base text-white font-poppins text-justify">
                  {research.details}
                </p>
              </div>
            ))}
          </>
        </div>
      </div>
    </div>
  );
}
