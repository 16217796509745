import React from "react";
import AinoviQ from "./AinoviQ";
import Contacts from "./Contacts";
import QuickLinks from "./QuickLinks";
import Services from "./Services";
import TermConditions from "./TermConditions";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Footer() {
  AOS.init({
    offset: 50,
    duration: 2000,
  });
  return (
    <div className="overflow-hidden p-4 max-w-7xl 2xl:max-w-hs mx-auto bg-slate-300 md:px-20">
      <div className="md:grid md:grid-cols-12 mx-2 md:mx-10" data-aos="fade-up">
        <div className="col-span-3">
          <Contacts />
        </div>
        <div className="col-span-3 md:border-r">
          <QuickLinks />
        </div>
        <div className="col-span-3 md:border-r">
          <Services />
        </div>
        <div className="col-span-3">
          <AinoviQ />
        </div>
      </div>
      <div className="bg-gray-200">
        <TermConditions />
      </div>
    </div>
  );
}
