import React from "react";
import { Link } from "react-router-dom";

export default function Contacts() {
  return (
    <div className="text-left md:text-right p-3 md:border-r">
      {/* <span class="hscroll-line"></span> */}

      <h1 className="text-2xl font-bold tracking-widest font-poppins text-slate-900">
        Contacts
      </h1>
      <div className="py-3">
        <p className="font-poppins text-sm leading-7 text-slate-600">
          <b>Address : </b>128/2, East Tejturi Bazar,Tejgaon, Dhaka-1215,
          Bangladesh
        </p>
        <p className="font-poppins text-sm leading-7 text-slate-600">
          <b>Email : </b>contact@ainoviqit.com
        </p>
        <p className="font-poppins text-sm leading-7 text-slate-600">
          <b>Phone : </b>+ 88016 2251 8087
        </p>
        <p className="font-poppins text-sm leading-7 text-slate-600">
          <b>Website : </b>
          <Link to={"https://ainoviqit.com/"}>www.ainoviqit.com</Link>
        </p>
      </div>
    </div>
  );
}
