import React from "react";

export default function TermConditions() {
  return (
    <div className="md:flex justify-around items-center py-5 text-gray-500 mx-auto">
      <div className="text-center items-center pb-2 md:pb-0">
        <h1 className="text-xs md:text-sm font-poppins">
          <span className="hover:text-red-500">Terms and Conditions</span> -{" "}
          <span className="hover:text-red-500">Privacy Policy</span>
        </h1>
      </div>
      <div className="text-center items-center">
        {/* <h1 className='text-sm'><strong className='text-gray-900'>AinoviQ </strong>is proudly owned by <strong className='text-gray-900'>©</strong></h1> */}
        <p className="text-xs md:text-sm font-poppins ">
          © 2023{" "}
          <strong className="text-gray-900 hover:text-red-500">
            AinoviQ IT Ltd.
          </strong>{" "}
          All rights reserved
        </p>
      </div>
    </div>
  );
}
