import React from "react";
import { Link } from "react-router-dom";

export default function ResearchDetailsdescription({ researchDetails, id }) {
  return (
    <div>
      <div className="grid grid-col-12 md:gap-6 p-2 md:p-0" key={id}>
        <div className="flex justify-between gap-2 md:px-4"></div>
        <div className="md:px-4 newCSS">
          <div className="flex justify-between items-center mb-10">
            <div className="">
              <h1 className="text-lg md:text-2xl my-auto font-extrabold tracking-wide textColor font-gfs ">
                {researchDetails.title}
              </h1>
            </div>
            <div className="">
              <Link to={id == 1 && "http://tryon.ainoviqit.com"} target="_blank">
                <button
                  className="btn2 px-10 py-5 relative text-white border border-white uppercase font-semibold tracking-wider leading-none overflow-hidden hover:text-teal-600"
                  type="button"
                >
                  <span className="absolute inset-0 bg-white"></span>
                  <span className="absolute inset-0 flex justify-center items-center font-bold">
                    Try On
                  </span>
                  Try On
                </button>
              </Link>
            </div>
          </div>
          {/* <h1 className="text-lg pb-6 font-apparel text-white">{posts.subtitle}</h1> */}
          <img
            src={researchDetails.imgUrl}
            alt="imag1e"
            className="w-92 md:w-[512px] md:h-auto  mx-auto"
          />
          <p className="py-6 text-white font-serif">
            {researchDetails.Description}
          </p>
          <div className="text-center">
            <Link to={id == 1 && "http://tryon.ainoviqit.com"} target="_blank">
              <button
                className="btn2 px-10 py-5 relative text-white border border-white uppercase font-semibold tracking-wider leading-none overflow-hidden hover:text-teal-600"
                type="button"
              >
                <span className="absolute inset-0 bg-white"></span>
                <span className="absolute inset-0 flex justify-center items-center font-bold">
                  Try On
                </span>
                Try On
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
