import React, { useEffect, useState } from "react";
import "../../../assets/styles/home.css";
import BgCover from "../../../assets/images/bg_cover.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
const ReadMore = ({ children, maxChar = 100 }) => {
  const text = children;
  const [isTruncated, setIsTruncated] = useState(true);
  const resultString = isTruncated ? text.slice(0, maxChar) : text;

  const toggleIsTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div className="medium-copy js-widow mb-1 text-sm md:text-base cursor-pointer bgTrans px-2 font-serif">
      {resultString}
      <span onClick={toggleIsTruncated} className="btn view-more inline-block">
        {isTruncated ? (
          <span className="border border-[#ff3d00] shadow-2xl rounded-md px-3 py-1 font-bold textColor">
            View more <FontAwesomeIcon icon={faAngleRight} />
          </span>
        ) : (
          <span className="border border-[#ff3d00] shadow-2xl rounded-md px-3 py-1 font-bold textColor">
            {" "}
            View less <FontAwesomeIcon icon={faAngleLeft} />
          </span>
        )}
      </span>
    </div>
  );
};

export default function Intro() {
  return (
    <div
      className="jumbotron min-h-full color-fg bg-bg flex align-items-start overflow-hidden "
      id="alignment"
    >
      <img
        id="alignment-cover"
        alt="bg"
        className="absolute w-100 min-h-screen object-cover flex overflow-hidden trbl-0 bg-cover js-lazy js-lazy-loaded bg-cover-animation"
        src={BgCover}
      />
      <div className="relative w-full md:w-1/2 my-10 md:my-bottom 2xl:mb-36 ">
        <div className="md:px-10 absolute left-2 top-0 md:top-1/4 text-white ">
          <h1 className="text-white text-2xl md:text-5xl font-gfs font-extrabold tracking-widest md:pb-3 2xl:pb-6">
            <span className="textColor text-3xl md:text-6xl">Empowering</span>{" "}
            The Future With Artificial Intelligence
          </h1>
          <ReadMore maxChar={200}>
            At AinoviQ, we are masters of machine perception, utilizing the
            extraordinary power of Computer Vision and Deep Learning to unlock
            the full potential of your business. Our AI-powered solutions enable
            you to create intelligent products, enhance performance, and
            streamline your processes like never before. Trust AinoviQ to bring
            the power of Computer Vision and Deep Learning to your business
            today.
          </ReadMore>
        </div>
      </div>

      {/* <img src={BgCover} alt="bg" className="absolute w-100 "/> */}
    </div>
  );
}
