import React from "react";
import Mission from "../../../assets/project-images/Cover1.png";
// import Mission1 from "../../../assets/project-images/cover3.png";
import { BiRightTopArrowCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
export default function OurMission() {
  return (
    <div className="  md:pb-44 2xl:pb-4 boxShadow1">
      <div className="md:grid md:grid-cols-12 p-2 md:p-4 md:gap-8 2xl:pt-24">
        <h1 className="text-2xl pt-3 tracking-wider font-bold block md:hidden mb-2 textColorBlack leading-10">
          <span className="textColor">Virtual Try On</span>: Empowering
          Businesses with AI-based Virtual Try On Technology
        </h1>
        <div className="col-span-4  mb-2 md:mb-0 rounded-lg 2xl:pt-24">
          <img src={Mission} alt="mission" className="rounded-lg" />
        </div>
        <div className="col-span-8">
          <div className="">
            <div className="col-span-8 relative">
              <h1 className="text-4xl pt-3 tracking-wider font-gfs hidden md:block textColorBlack">
                <span className="textColor font-bold">Virtual Try-On</span> :
                <span className="">
                  Empowering Businesses with AI-based Virtual Try On Technology
                </span>
              </h1>
              <div className="col-span-8 md:absolute  md:mt-26 pt-5 md:pt-20 textColorBlack">
                <p className=" text-2xl tracking-wide font-poppins">
                  Our Mission : Fueling Business with AI Solutions
                </p>
                <div className="md:grid md:grid-cols-12 pt-5">
                  <div className="col-span-12 ">
                    <div className="md:flex px-4 md:px-0">
                      <div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Transforming the way customers interact with
                            products.{" "}
                          </p>
                        </div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Enabling seamless and immersive shopping experiences
                            for businesses.{" "}
                          </p>
                        </div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Cost-effective virtual product try-on solution.{" "}
                          </p>
                        </div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Leveraging AI to enhance customer engagement and
                            satisfaction.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Boost sales and revenue with enhanced customer
                            experience.{" "}
                          </p>
                        </div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Reducing physical try-ons to enhance business
                            efficiency{" "}
                          </p>
                        </div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Expand business reach with virtual try-on for wider
                            accessibility.{" "}
                          </p>
                        </div>
                        <div className="flex items-start pb-4">
                          <BiRightTopArrowCircle
                            size={20}
                            className="textColor"
                            style={{ flexShrink: 0 }}
                          />
                          <p className="pl-3 font-poppins text-sm md:text-sm">
                            {" "}
                            Reducing the environmental impact of physical
                            product try-ons{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-start md:pt-8 2xl:pt-16 ml-20 md:ml-0">
                      <Link to={"http://tryon.ainoviqit.com"} target="_blank">
                        <button
                          className="btn2 mt-2 px-10 py-5 relative border border-black uppercase font-semibold tracking-wider leading-none overflow-hidden bg-[#ff3d00] hover:text-white text-white"
                          type="button"
                        >
                          <span className="absolute inset-0 bg-teal-600"></span>
                          <span className="absolute inset-0 flex justify-center items-center font-bold">
                            Discover More
                          </span>
                          Discover More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
