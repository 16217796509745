import React from "react";
import { Link } from "react-router-dom";

export default function Services() {
  return (
    <div className="left md:text-right p-3">
      <h1 className="text-2xl font-bold tracking-widest font-poppins text-slate-900">
        Services
      </h1>
      <ul className="font-poppins text-sm  py-3 text-slate-600">
        <li className="pb-2">
          <Link to={"services"}>Virtual Try-On</Link>
        </li>
        <li className="pb-2">
          <Link to={"services"}>Fashion Gan</Link>
        </li>
        <li className="pb-2">
          <Link to={"services"}>Deepfake Generator</Link>
        </li>
        <li className="pb-2">
          <Link to={"services"}>Fake Image Detection</Link>
        </li>
      </ul>
    </div>
  );
}
