import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../../assets/logo/robot.png";
export default function Contact_home() {
  return (
    <div>
      <div className="contactCSS md:flex pt-20  px-4 lg:px-10 boxShadow1">
        <div className="w-full pt-14 hidden md:block">
          <img src={Contact} alt="contact" className="" />
        </div>
        <div className="pb-10">
          <h1 className="pb-6 font-lato font-bold text-2xl md:text-5xl text-white">
            Unlock The Potential Of AI With{" "}
            <span className="textColor font-bold font-orbitron">AinoviQ</span>
          </h1>
          <div className="pb-6 text-gray-400 font-serif">
            <p>
              Think you've reached the limits of what AI can do for your
              business?
            </p>
            <p className="textColor font-bold font-orbitron text-lg">
              Think again.
            </p>{" "}
            <p>AinoviQ can help you unlock its full potential.</p>
          </div>
          <div className="text-center">
            <Link to={"/contact"} className="">
              <button
                className="btn2 text-white px-10 py-2 items-center relative  uppercase font-semibold tracking-wider leading-none overflow-hidden hover:text-[#ff3d00] bg-[#ff3d00]"
                type="button"
              >
                <span className="absolute inset-0 bg-white"></span>
                <span className="absolute inset-0 flex justify-center items-center font-bold">
                  Contact Us
                </span>
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
