import React from 'react'
import OfferHeading from './OfferHeading'
import OfferList from './OfferList'

export default function Offers() {
  return (
    <div>
        <OfferHeading />
        <OfferList />
    </div>
  )
}
