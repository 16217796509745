import React from "react";
// import Logo from "../../../assets/logo/ainoviq_logo.png";
import { Link } from "react-router-dom";
import { TiSocialLinkedinCircular } from "react-icons/ti";
export default function AinoviQ() {
  return (
    <div className="text-left md:text-right p-3">
      <div className="flex md:justify-end">
        <h1 className="my-auto text-2xl font-bold tracking-widest font-poppins text-slate-900">
          Social Media
        </h1>
      </div>
      <div className="inline-block">
        <Link
          to={"https://linkedin.com/company/ainoviqit"}
          target="_blank"
          className=" text-slate-600"
        >
          <TiSocialLinkedinCircular size={50} className="items-end" />
        </Link>
      </div>
    </div>
  );
}
