import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Img11 from "../../../assets/project-images/11.png";
import Img12 from "../../../assets/project-images/12.png";
import Img13 from "../../../assets/project-images/7.png";
import Img14 from "../../../assets/project-images/14.png";

import { useNavigate } from "react-router-dom";

const researchs = [
  {
    logo: <img src={Img12} alt="afd" className=" bg-white w-full h-full " />,
    heading: " Deep Fake",
    details:
      "Deepfake research involves the development and analysis of techniques and technologies used to create, detect, and mitigate deep fake videos and images. It typically includes research on machine learning, computer vision, and other related fields, and seeks to improve our understanding of the risks and benefits associated with deepfake technology. The ultimate goal of deepfake research is to develop effective tools and strategies for identifying and preventing the spread of harmful deepfake content while also exploring potential positive applications of the technology.",
  },
  {
    logo: <img src={Img13} alt="afd" className=" bg-white w-full h-full " />,
    heading: " Remote Sensing",
    details:
      "Gathering and analyzing information about objects or phenomena from a distance using remote sensors, and using this data to gain insights and make predictions about various phenomena such as weather patterns, land use, and natural disasters. It has applications in fields such as agriculture, environmental monitoring, urban planning, and natural resource management.",
  },
  {
    logo: <img src={Img14} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Transfer Learning",
    details:
      "Branch of deep learning focusing on transferring knowledge learned from one domain to another. It involves using pre-trained models on large datasets to accelerate training on new datasets with limited labeled data. Transfer learning research aims to develop effective techniques for transferring knowledge between different domains and improving the performance of deep learning models in various applications.",
  },
];

export default function Researchs() {
  const navigate = useNavigate();
  const [maxResearchs, setMaxResearchs] = useState(() => {
    return window.innerWidth < 600 ? 1 : researchs.length;
  });

  const handleResize = () => {
    const newMaxResearchs = window.innerWidth < 600 ? 1: researchs.length;
    setMaxResearchs(newMaxResearchs);
  }
  useEffect(() => {
    window.addEventListener('resize' , handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);

  AOS.init({
    offset: 200,
    duration: 3000,
  });
  return (
    <div className="md:first-line 2xl:mx-36 ">
      <div data-aos="fade-down">
        <h1 className="font-orbitron text-2xl md:text-5xl pb-10 text-white font-bold newCSSServices text-center">
          Research & Innovation
        </h1>
      </div>
      <div className="lg:grid lg:grid-cols-12 gap-4 m-4 md:m-0">
        <div className="col-span-8 p-2  md:p-8 pt-10  rounded-lg bg-[#ec6137] my-2 md:my-0 boxShadow1">
          <h1 className="text-2xl md:text-3xl text-black font-gfs font-extrabold tracking-widest pb-4 pt-2">
            State of the Art <strong className="text-white">AI </strong>{" "}
            Research & Deployment
          </h1>
          <p className="text-sm md:text-base text-black font-poppins text-justify">
            AinoviQ is dedicated to pushing the boundaries of artificial
            intelligence and innovation to develop cutting-edge products and
            services. We invest heavily in research and development to stay at
            the forefront of the AI industry, leveraging state-of-the-art
            technologies and techniques to drive innovation and bring our
            clients the most advanced solutions. Our team of experts has deep
            expertise in computer vision, natural language processing, and
            machine learning, enabling us to create customized solutions that
            address even the most complex business challenges. Through our
            AI-powered products and services, we empower our clients to stay
            competitive in today's rapidly evolving digital landscape and unlock
            new opportunities for growth and success.
          </p>
        </div>
        <div
          className="col-span-4  p-4 md:p-8 2xl:p-8 bg-gray-600 rounded-2xl newCSS my-2 md:my-0"
          data-aos="fade-up"
        >
          <div
            className="w-14 h-14 overflow-hidden rounded-full"
            style={{ flexShrink: 0 }}
          >
            <img src={Img11} alt="jjf" />
            {/* {Img11} */}
          </div>
          <h1 className="text-xl md:text-2xl font-gfs font-extrabold  tracking-widest pb-2 pt-2 text-[#ff3d00]">
            Data Augmentation
          </h1>
          <p className="text-sm md:text-base text-white font-poppins text-justify">
            Exploring techniques to increase the diversity and size of a dataset
            used for training a machine learning model. This process involves
            creating new data samples by applying various transformations to the
            existing data samples, such as flipping, rotating, and cropping
            images. The goal of data augmentation research is to improve the
            generalization and robustness of machine learning models by
            providing them with more diverse and representative data samples.
          </p>
        </div>

        <>
          {researchs.slice(0,maxResearchs).map((research) => (
            <div
              className="col-span-4 p-4 md:px-8  bg-gray-600 rounded-2xl newCSS my-2 md:my-0"
              data-aos="fade-up"
              key={research.heading}
            >
              {/* {research.logo} */}
              <div
                className="w-14 h-14 overflow-hidden rounded-full"
                style={{ flexShrink: 0 }}
              >
                {research.logo}
              </div>
              <h1 className="text-xl md:text-2xl font-gfs font-extrabold  tracking-widest pb-2 pt-2 text-[#ff3d00]">
                {research.heading}
              </h1>
              <p className="text-sm md:text-base text-white font-poppins text-justify">
                {research.details}
              </p>
            </div>
          ))}
        </>
        <div className="col-span-12 text-center pt-4 pb-16">
          <button
            className="btn2 text-black md:text-white bg-[#ff3d00] px-10 py-5 relative  md:border-white uppercase font-semibold tracking-wider leading-none overflow-hidden hover:text-white"
            type="button"
            onClick={() => {
              navigate(`/research-and-innovation`);
            }}
          >
            <span className="absolute inset-0 bg-teal-600"></span>
            <span className="absolute inset-0 flex justify-center items-center font-bold">
              More Research
            </span>
            More Research
          </button>
        </div>
      </div>
    </div>
  );
}
