import { useState , useEffect } from "react";
import axios from "axios";

export default function Contact_us() {
  // const [agreed, setAgreed] = useState(false);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);

  const onSendMessage = (evt) => {
    evt.preventDefault();

    if (
      name === "" ||
      company === "" ||
      email === "" ||
      subject === "" ||
      message === ""
    ) {
      setError("Something went wrong. Try again later.");
      setTimeout(() => {
        setError(null);
      }, 1000 * 5);
    } else {
      const data = {
        name: name,
        company: company,
        email: email,
        subject: subject,
        message: message,
      };
      setMailSent(true);
      setTimeout(() => {
        setMailSent(false);
      }, 1000 * 5);

      axios
        .post("mail_server/index.php", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-900 py-10">
      <div className="isolate py-10 md:py-12 px-6 sm:py-10 sm:pb-10 lg:px-8 w-11/12 md:w-2/5 mx-auto newCSS ">
        <div className="absolute inset-x-0 top-[-20] md:top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-10 ">
          <svg
            className="relative left-1/2 -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-40rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-wide textColor  font-orbitron sm:text-4xl">
            Reach Us Here
          </h2>
          <p className="mt-2 text-sm md:text-base leading-8 text-gray-300 font-poppins">
            Need to get in touch with us? Here's how to reach our team of AI
            experts
          </p>
        </div>
        <form
          method="POST"
          id="contactForm"
          name="contactForm"
          onSubmit={onSendMessage}
          noValidate="novalidate"
          className="mx-auto mt-2 md:mt-10 max-w-xl sm:mt-10"
        >
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-sm font-semibold leading-6 text-gray-400 font-poppins"
              >
                Your name <span className="text-red-500">*</span>
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Your name"
                  className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-poppins"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-semibold leading-6 text-gray-400 font-poppins"
              >
                Company <span className="text-red-500">*</span>
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="company"
                  id="company"
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                  placeholder="Company"
                  className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-poppins"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-400 font-poppins"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email"
                  className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-poppins"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="block text-sm font-semibold leading-6 text-gray-400 font-poppins"
              >
                Subject <span className="text-red-500">*</span>
              </label>
              <div className="mt-2.5">
                <input
                  type="subject"
                  name="subject"
                  id="subject"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  placeholder="Your subject"
                  className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-poppins"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-gray-400 font-poppins"
              >
                Message <span className="text-red-500">*</span>
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  onChange={(evt) => setMessage(evt.target.value)}
                  value={message}
                  placeholder="Your message"
                  rows={4}
                  className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-poppins"
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <input
              type="submit"
              value="Let's talk"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-poppins font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            />
          </div>
          <div className="mt-10">
            <div className="form-group">
              {mailSent && (
                <div className="bg-green-100 px-3 py-2 text-green-700 rounded-lg">
                  Thank you for contacting us. We will reach you soon.
                </div>
              )}
              {!error ? (
                ""
              ) : (
                <div className="bg-red-100 px-3 py-2 text-red-700 rounded-lg">
                  {error}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
