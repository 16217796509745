import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Wrapper from "./components/handler/Wrapper";
import Home from "./components/pages/home/Home";
import About from "./components/pages/about/About";
// import Blog from "./components/pages/blog/Blog";
import Contact from "./components/pages/contact-us/Contact_us";
// import BlogDetails from "./components/pages/blog/BlogDetails";
import NotFound from "./components/handler/error/NotFound";
import Reserach from "./components/pages/reserach/Reserach";
import ResearchDetails from "./components/pages/reserach/ResearchDetails";
// import OfferList from "./components/pages/offer/OfferList";
import Offers from "./components/pages/offer/Offers";
import ResearchList from "./components/pages/home/ResearchList";
// import Services from "./components/pages/services/Services";
// import ServicesDetails from "./components/pages/services/ServicesDetails";
// import BlogList from "./components/pages/blog/BlogList";
// import BlogList from "./components/pages/blog/BlogList";


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Wrapper>
        <Outlet />
      </Wrapper>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/offer-list",
        element: <Offers />
      },
      {
        path: "services",
        element: <Reserach />,
      },
      // {
      //   path: "services",
      //   element: <Services />
      // },
      // {
      //   path:"services/:id",
      //   element : <ServicesDetails />
      // },
      {
        path: "services/:id",
        element: <ResearchDetails />,
      },
      {
        path: "/research-and-innovation",
        element : <ResearchList />
      },
      {
        path: "about",
        element: <About />,
      },
      // {
      //   path:"blog",
      //   element: <Blog />,
      // },
      // {
      //   path: "blog/:id",
      //   element: <BlogDetails />
      // },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="bg-gray-300 ">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
