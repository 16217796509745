import React from "react";
import { useEffect } from "react";
import Img1 from "../../../assets/project-images/1.png";
import Img2 from "../../../assets/project-images/2.png";
import Img3 from "../../../assets/project-images/3.png";
import Img4 from "../../../assets/project-images/4.png";
import Img5 from "../../../assets/project-images/5.png";
import Img6 from "../../../assets/project-images/6.png";
import Img7 from "../../../assets/project-images/7.png";
import Img8 from "../../../assets/project-images/8.png";
import Img9 from "../../../assets/project-images/9.png";
import Img10 from "../../../assets/project-images/10.png";
// import OfferList from "../offer/OfferList";

const offers = [
  {
    logo: <img src={Img1} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Virtual Try-On",
    details:
      "Developing computer vision-based systems for virtual try-on experiences, such as virtual fitting rooms for clothing or makeup, allowing customers to try on products without physically being present.",
  },
  {
    logo: <img src={Img2} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Fashion Recommendation",
    details:
      "Providing computer vision-based solutions for fashion recommendation, such as personalized styling advice and product recommendations based on body type and preferences.",
  },
  {
    logo: <img src={Img3} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Intelligent Video Analytics",
    details:
      " Providing advanced video analytics services to clients, such as real-time object tracking, facial recognition, and automated video surveillance.",
  },
  {
    logo: <img src={Img4} alt="afd" className=" bg-white w-full h-full " />,
    heading: "3D Vision",
    details:
      "Developing solutions that allow for 3D sensing, reconstruction, and modeling. This can be used for applications such as 3D scanning, virtual and augmented reality, and autonomous navigation.",
  },
  {
    logo: <img src={Img5} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Natural Language Processing (NLP)",
    details:
      "Building systems that can analyze and interpret human language, including speech recognition, sentiment analysis, and language translation.",
  },
  {
    logo: <img src={Img6} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Medical Image Analysis",
    details:
      "Developing solutions for medical image analysis, such as tumor detection, image segmentation, and diagnosis support.      ",
  },
  {
    logo: <img src={Img7} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Remote Sensing",
    details:
      "Building computer vision-based systems for remote sensing applications, such as environmental monitoring, disaster management, and agriculture.",
  },
  {
    logo: <img src={Img8} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Smart Agriculture",
    details:
      " Developing computer vision-based solutions for agriculture, such as crop health monitoring, yield prediction, and pest detection.      ",
  },
  {
    logo: <img src={Img9} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Security and Surveillance",
    details:
      "Providing computer vision-based services for security and surveillance, such as facial recognition, crowd analysis, and anomaly detection.",
  },
  {
    logo: <img src={Img10} alt="afd" className=" bg-white w-full h-full " />,
    heading: "Image and Video Enhancement",
    details:
      "Providing computer vision-based services for enhancing images and videos, such as upscaling, denoising, and restoration.",
  },
];
export default function OfferList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" bg-gray-900 ">
      <div className="md:grid md:grid-cols-12 max-w-7xl 2xl:max-w-hs md:px-20 mx-auto">
        <div className="md:col-span-12 p-2 md:py-4 right">
          <div className="lg:grid lg:grid-cols-8">
            {offers.map((offer) => (
              <div
                className="col-span-4 p-4 md:px-8 m-4 md:m-2 bg-gray-600 rounded-2xl hover:shadow-2xl box bg-opacity-50 newCSS"
                data-aos="fade-up"
                key={offer.heading}
              >
                <div className="flex justify-between items-center text-right hover:pb-2 hover:border-b border-[#2edaf1] ">
                  <div
                    className="w-14 h-14 overflow-hidden rounded-full"
                    style={{ flexShrink: 0 }}
                  >
                    {offer.logo}
                  </div>
                  <h1 className="text-xl md:text-2xl ml-4  font-gfs font-extrabold tracking-widest py-2  textColor">
                    {offer.heading}
                  </h1>
                </div>
                <p className="text-white text-sm md:text-base pt-2 text-justify font-poppins">
                  {offer.details}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
