import React from "react";
import Contact from "./Contact_home";
import Research from "./Researchs";
import Intro from "./Intro";
// import LatestBlog from "./LatestBlog";
// import Moveing from "./Moveing";
import Offer from "./Offer";
import VirtulaTryOn from "./VirtulaTryOn";
import Services from "./Services";
// import Text from "./Text";

export default function Home() {
  return (
    <div className=" bg-gray-900 ">
      <section className="mb-2 md:pb-10">
        <Intro />
      </section>
      {/* <section className="">
        <Moveing />
      </section> */}
      <div className="max-w-7xl 2xl:max-w-hs mx-auto mt-2">
        <section className="md:px-5 lg:px-20 mb-8 min-h-screen ">
          <VirtulaTryOn />
        </section>
        <section className="md:px-5 lg:px-20 ">
          <Offer />
        </section>
      </div>
      
      <section className="my-16 py-20 overflow-hidden ">
        <Services />
      </section>
      <section className="md:px-5 lg:px-20 py-5 overflow-hidden ">
        <Research />
      </section>

      <div className="max-w-7xl 2xl:max-w-hs mx-auto ">
        {/* <section className="md:px-20 md:py-30 2xl:py-52  md:mb-10 my-auto">
          <LatestBlog />
        </section> */}
        <section className="px-5 md:px-5 lg:px-20 md:py-30 2xl:py-52 my-10 md:my-10 lg:min-h-screen">
          <Contact />
        </section>
      </div>
       

      {/* </div> */}
    </div>
  );
}
