import React from "react";

export default function Hero() {
  return (
    <div className="md:grid md:grid-cols-12 md:gap-4 pt-10">
      <div className="md:col-span-6">
        <h1 className="text-3xl md:text-5xl mb-2 md:pb-4 hover:underline hover:underline-offset-4 hover:transition-all textColor font-montserrat">
          About Us
        </h1>
        <div className="p-5">
          <p className="text-xl font-gfs   md:text-3xl font-extrabold text-left pb-5 textColorBlack">
            Artificial Intelligence Is Not A Matter of Science Fiction Anymore
          </p>
          <p className="font-poppins text-white text-sm md:text-base ">
            We're turning the once-seemingly impossible possibilities of
            artificial intelligence into reality. With our cutting-edge
            technology and expertise, we're helping businesses across industries
            harness the full potential of AI to drive growth, optimize
            performance, and stay ahead of the competition. Say goodbye to
            science fiction and hello to the limitless possibilities of AI with
            AinoviQ.
          </p>
        </div>
      </div>

      {/* <div className="md:col-span-6 w-full  aspect-video box rounded-2xl my-auto">
        <iframe
          title="mission"
          className="w-full h-full rounded-2xl"
          src="https://www.youtube.com/embed/r9jwGanp1E"
          frameBorder="0"
          // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allow="autoplay"
          allowFullScreen
        ></iframe>
      </div> */}
    </div>
  );
}
