import React, { useState, useEffect } from "react";
import { BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Team.css";

export default function Team() {
  const [people, setPeople] = useState([]);
  const getData = () => {
    fetch("people.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response)
        return response.json();
      })
      .then(function (myJson) {
        // console.log(myJson);
        setPeople(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className=" py-12 sm:py-22 rounded-t-lg">
      <div className="mx-auto grid max-w-7xl gap-y-10 gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl left">
          <div className="sticky1 pad">
            <h2 className="font-gfs font-extrabold text-2xl md:text-4xl text-left tracking-widest textColor">
              Meet Our Core Members
            </h2>
            <p className="mt-6 text-sm md:text-base leading-8 text-white font-poppins">
              Hailing from different regions and cultures around Bangladesh, our
              team brings a wealth of experience and perspective to every
              project we tackle. Together, we collaborate to create cutting-edge
              AI-powered solutions that help our clients achieve their business
              goals. Get to know our talented team and see how we can help take
              your business to the next level.
            </p>
          </div>
        </div>
        <ul className="grid md:gap-x-8 gap-y-6 sm:grid-cols-2 sm:gap-y-12 xl:col-span-2">
          {people.map((person) => (
            <div className="teamCard css-109v7p3">
              <div className="teamBg">
                <img src={person.imageUrl} alt="team" />
              </div>
              <div className="about">
                <h3>{person.name}</h3>
                <p>{person.role}</p>
                <div className="socialIcons">
                  <div className="circle">
                    <Link to={person.linkedin} target="_blank">
                      <BsLinkedin size={30} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}
