import React from "react";
import ImgRe from "../../../assets/project-images/glaze.gif";
export default function ResearchHeading() {
  return (
    <div className="relative items-center text-center justify-center flex">
      <div className="relative">
        <img
          src={ImgRe}
          alt="research"
          className=" opacity-50 object-cover w-screen mx-auto h-[512px]"
        />
      </div>
      <h1 className="absolute items-center justify-center flex  text-white font-extrabold text-xl md:text-5xl 2xl:text-7xl font-orbitron">
        Explore Our Best AI Services
      </h1>
    </div>
  );
}
