import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../common/footer/Footer'
import Navbar from '../common/header/navbar/Navbar'

export default function Wrapper() {
  return (
    <div>
        <Navbar />
        <div className='bgColor'>
            <Outlet/>
        </div>
        <Footer />
    </div>
  )
}
