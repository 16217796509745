import { useEffect } from "react";
import Hero from "./Hero";
import Team from "./Team";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" bg-gray-900">
      <div className="max-w-7xl mx-auto 2xl:max-w-hs">
        <section className="mx-2 md:px-20 pb-10 min-h-full">
          <Hero />
        </section>
        <section className="px-2 md:px-20">
          <Team />
        </section>
      </div>
    </div>
  );
}
