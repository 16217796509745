import { GiArtificialIntelligence } from "react-icons/gi";

export const data = [
  {
    id: 1,
    title: "Virtual Try On",
    library: (
      <GiArtificialIntelligence
        size={40}
        className=" -rotate-45 my-auto mx-auto"
      />
    ),
    shortDes:
      "Sollicitudin massa maecenas purus adipiscing egestas natoque fringilla odio ac sodales",
  },
  {
    id: 2,
    title: "Virtual Try On",
    library: (
      <GiArtificialIntelligence
        size={40}
        className=" -rotate-45 my-auto mx-auto"
      />
    ),
    shortDes:
      "Sollicitudin massa maecenas purus adipiscing egestas natoque fringilla odio ac sodales",
  },
  {
    id: 3,
    title: "Virtual Try On",
    library: (
      <GiArtificialIntelligence
        size={40}
        className=" -rotate-45 my-auto mx-auto"
      />
    ),
    shortDes:
      "Sollicitudin massa maecenas purus adipiscing egestas natoque fringilla odio ac sodales",
  },
  {
    id: 4,
    title: "Virtual Try On",
    library: (
      <GiArtificialIntelligence
        size={40}
        className=" -rotate-45 my-auto mx-auto"
      />
    ),
    shortDes:
      "Sollicitudin massa maecenas purus adipiscing egestas natoque fringilla odio ac sodales",
  },
  {
    id: 5,
    title: "Virtual Try On",
    library: (
      <GiArtificialIntelligence
        size={40}
        className=" -rotate-45 my-auto mx-auto"
      />
    ),
    shortDes:
      "Sollicitudin massa maecenas purus adipiscing egestas natoque fringilla odio ac sodales",
  },
  {
    id: 6,
    title: "Virtual Try On",
    library: (
      <GiArtificialIntelligence
        size={40}
        className=" -rotate-45 my-auto mx-auto"
      />
    ),
    shortDes:
      "Sollicitudin massa maecenas purus adipiscing egestas natoque fringilla odio ac sodales",
  },
];
