import React from "react";
import { Link } from "react-router-dom";

export default function QuickLinks() {
  return (
    <div className="text-left md:text-right p-3">
      <h1 className="text-2xl font-bold tracking-widest font-poppins text-slate-900">
        Quick Links
      </h1>
      <div className="font-poppins text-sm leadding-7 py-3 text-slate-600">
        <ul>
          <li className="pb-2">
            <Link to="/about">About</Link>
          </li>
          {/* <li className="pb-2">
            <Link to={'/blog'}>Blog</Link>
          </li> */}
          <li className="pb-2">
            <Link to={"/services"}>Services</Link>
          </li>
          <li className="pb-2">
            <Link to={"/contact"}>Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
